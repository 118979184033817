<!-- 获奖名单 -->
<!--
 * 
 *邀请有奖
-->

<template>
  <div>
    <div id="winnerList">
      <div class="commonList" :style="isApp ? 'padding-top:20px;' : ''">
        <div class="title">邀请榜TOP100获奖名单</div>
        <div class="desc">
          恭喜以下用户获得《仙武帝尊》系列的一份指定藏品和一份盲盒空投，5个工作日内完成空投
        </div>
        <!-- <div class="rankBox">
        <div class="rankList">
          <div class="rankItem" v-for="(item, index) in tab2Name" :key="index">
            <div class="userInfo">
              <div class="number" :style="index < 3 ? 'background: #3CDBE8;color:black;' : ''">
                {{ index + 1 }}
              </div>
              <div class="name">
                <div :style="index < 3 ? 'color:#3CDBE8' : ''">{{ item.user_name }}</div>
              </div>
            </div>

            <div>
              邀请<span class="inviteNum">{{ item.num }}</span
              >人
            </div>
          </div>
        </div>
      </div> -->

        <div class="awardList" v-for="(item, index) in awardList" :key="index">
          <div class="titleTip">{{ item.title }}</div>
          <div class="nameBox">
            <span v-for="(sonItem, sonIndex) in item.list" :key="sonIndex">{{
              sonItem.user_name
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue';
import { getactivityuserlist } from '@/service/invite';
import { useRoute } from 'vue-router';
import { nftUtils } from '@/utils';

export default {
  name: 'winnerList',
  setup() {
    const route = useRoute();
    const state = reactive({
      tabs: [
        {
          value: 1,
          name: '名单一'
        },
        {
          value: 2,
          name: '名单二'
        }
      ],
      activeTab: 1,
      tab1Name: [],
      tab2Name: [],
      activityId: route.query.activityId,
      isApp: nftUtils.isInApp(),
      awardList: []
    });

    const listTabs = value => {
      state.activeTab = value;
    };

    const getList = () => {
      getactivityuserlist({
        activity_id: state.activityId
      }).then(res => {
        console.log(res);
        if (res.status == 0) {
          for (let i in res.data) {
            if (res.data[i].reward_type == 1) {
              state.tab1Name = res.data[i].users;
            } else if (res.data[i].reward_type == 2) {
              state.tab2Name = res.data[i].users;
            }
          }

          if (state.tab1Name.length) {
            state.awardList = [
              {
                title: 'TOP1 获得传说级荒古大帝（叶辰）一份、《仙武帝尊》盲盒*1',
                list: state.tab1Name.slice(0, 1)
              },
              {
                title: 'TOP2-10 获得史诗级藏品随机一份、《仙武帝尊》盲盒*1',
                list: state.tab1Name.slice(1, 10)
              },
              {
                title: 'TOP11-30 获得稀有级藏品随机一份、《仙武帝尊》盲盒*1',
                list: state.tab1Name.slice(10, 30)
              },
              {
                title: 'TOP31-100 获得普通级藏品随机一份、《仙武帝尊》盲盒*1',
                list: state.tab1Name.slice(30, 100)
              }
            ];
          }

          console.log(state.awardList);
        }
        // if(res.)
      });
    };

    onMounted(() => {
      getList();
    });

    return {
      ...toRefs(state),
      listTabs
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
* {
  touch-action: pan-y;
}
#winnerList {
  .commonList {
    padding: 0 20px 20px 20px;
    padding-top: 70px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;

    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
    }

    .desc {
      font-size: 12px;
      font-weight: 300;
      color: rgba(255, 255, 255, 0.7);
      line-height: 26px;
    }

    .nameList {
      margin-top: 8px;

      span {
        display: inline-block;
        width: 50%;
        text-align: left;
        padding-bottom: 8px;
      }
    }

    .rankList {
      .rankItem {
        margin-top: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        font-weight: 400;

        .userInfo {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .number {
          color: rgba(255, 255, 255, 1);
          width: 28px;
          height: 28px;
          background: rgb(54, 51, 64);
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          line-height: 28px;
          border-radius: 50%;
        }

        .myNumber {
          background: #fff;
          color: black;
        }

        .headImg {
          margin-left: 20px;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 2px solid #3cdbe8;
            object-fit: cover;
          }
        }

        .name {
          margin-left: 16px;

          :first-child {
            font-size: 14px;
            font-weight: 600;
          }

          :last-child {
            color: rgba(255, 255, 255, 0.7);
          }
        }

        .inviteNum {
          color: #3cdbe8;
        }
      }
    }
  }

  .awardList {
    margin-top: 20px;
    .titleTip {
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
    }

    .nameBox {
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);

      span {
        display: inline-block;
        width: 50%;
        margin-top: 8px;
      }
    }
  }
}
</style>
